import moment from "moment";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { useBreakpoints } from "hooks";
import { Container, Flex } from "components/Grids";
import { P2, P3 } from "components/Typography";
import SocialBar from "components/SocialBar";
import { Link } from "components/Links";
import Icon from "components/Icon";
import Logo from "../LayoutLogo";
import SubscribeForm from "./SubscribeForm";
import SitMap from "./SitMap";
import Wrapper from "./styles";

export default function Footer({ small }: { small?: boolean }) {
  const { colors } = useTheme();
  const { t } = useTranslation("app");

  const { medium } = useBreakpoints();

  return (
    <Wrapper
      pv="50px"
      as="footer"
      align="center"
      justify="center"
      gap={{ xs: 10, md: 20, lg: 40 }}
      fullWidth
    >
      <Container width="extraWide">
        {!small && (
          <>
            <Flex
              gap={{ xs: 8, md: 16, lg: 32 }}
              pb="40px"
              direction="column"
              fullWidth
            >
              <Logo color="white" />
            </Flex>
            <Flex
              direction={{ xs: "column", md: "row" }}
              gap={{ xs: 15, md: 30, lg: 60 }}
              fullWidth
            >
              <SubscribeForm />
              <SitMap />
            </Flex>
          </>
        )}
        <Flex
          mt={small ? "20px" : "40px"}
          pt={small ? "0px" : "40px"}
          gap={{ xs: 10, md: 20, lg: 40 }}
          justify="space-between"
          align="center"
          className={small ? "" : "footer--lower"}
          fullWidth
          direction={{ xs: "column", md: "row" }}
        >
          <P3 color={colors.shades[100]}>
            {t("copyrights", {
              year: moment().year(),
            })}
          </P3>
          <Link
            relative={false}
            to="https://eauthenticate.saudibusiness.gov.sa/certificate-details/0000023559"
          >
            <Icon size={medium ? 250 : 150} name="sbc" />
          </Link>
          <Flex direction="column" align="center" gap="12px">
            <P2 text="needHelp" color={colors.shades[100]} />
            <SocialBar />
          </Flex>
        </Flex>
      </Container>
    </Wrapper>
  );
}
