import Spinner from "components/Spinner";
import { useBreakpoints } from "hooks";
import { useTheme } from "@emotion/react";
import Icon from "components/Icon";
import configs, { sizes } from "../configs";
import { StyledIconButton } from "../styles";
import { IconButtonType } from "../types";

export default function IconButton({
  icon,
  onClick,
  className,
  isLoading,
  disabled,
  iconSize,
  iconColor,
  variant = "primary",
  size = "lg",
  ...rest
}: IconButtonType) {
  const { iconButton } = sizes;
  const { large, medium } = useBreakpoints();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { sm, md, lg } = iconButton[size].iconSize;
  const { colors } = useTheme();

  return (
    <StyledIconButton
      disabled={isLoading || disabled}
      className={className}
      onClick={onClick}
      variant={variant}
      size={size}
      {...rest}
    >
      {isLoading ? (
        <Spinner topColor={colors.grey[300]} bottomColor={colors.grey[100]} />
      ) : (
        <Icon
          name={icon}
          size={iconSize || (large ? lg : medium ? md : sm)}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          color={
            disabled
              ? colors.grey[400]
              : rest.color || iconColor || configs.button[variant].styles.color
          }
        />
      )}
    </StyledIconButton>
  );
}

IconButton.displayName = "iconButton";
