import React from "react";
import {
  BrowserRouter as Router,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import "locales";
import { store, persister } from "app/state";
import AppProvider from "./AppProvider";

type LayoutType = { children: React.ReactNode };

export default function withAppProviders<T extends {}>(
  WrappedComponent: React.ComponentType<T>,
  Layout?: React.ComponentType<LayoutType>,
) {
  function Wrapper(props: T) {
    if (process.env.NODE_ENV === "production") {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_KEY,
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              React.useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes,
            ),
          }),
          new Sentry.Replay(),
        ],
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 0,
      });
    }
    return (
      <ReduxProvider store={store}>
        <PersistGate
          loading={<span>loading persisted Data</span>}
          persistor={persister}
        >
          <Router>
            <AppProvider>
              {Layout ? (
                <Layout>
                  <WrappedComponent {...props} />
                </Layout>
              ) : (
                <WrappedComponent {...props} />
              )}
            </AppProvider>
          </Router>
        </PersistGate>
      </ReduxProvider>
    );
  }

  return Wrapper;
}
