import React from "react";
import { useAppSelector } from "hooks";
import { Flex } from "components/Grids";
import UserInfo from "components/UserInfo";
import ToggleLanguage from "./ToggleLanguage";

type Props = {
  transparentMode: boolean;
};

export default function LoggedUser({ transparentMode }: Props) {
  const {
    consumerAuth: { user },
    ui: { language },
  } = useAppSelector((state) => state);
  const customerName = user.customer_names[language];

  return (
    <Flex align="center" gap="24px" fullHeight>
      <ToggleLanguage transparentMode={transparentMode} />
      <UserInfo
        firstName={customerName?.firstName || ""}
        lastName={customerName?.lastName || ""}
        image={user.avatar}
        transparentMode={transparentMode}
      />
    </Flex>
  );
}
