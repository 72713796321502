/* eslint-disable import/prefer-default-export */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import devices from "theme/sizes";
import { Flex } from "components/Grids";

export const WhatsappWrapper = styled(Flex)(
  ({ theme: { shadows } }) => css`
    position: fixed;
    top: 80vh;
    left: 2rem;
    width: 40px;
    transition: transform 0.3s ease-in-out;
    box-shadow: ${shadows[1]};
    border-radius: 10px;
    &:hover {
      transform: scale(1.2);
    }
    ${devices.md} {
      top: 90vh;
      width: 50px;
    }
    img {
      width: 100%;
      object-fit: cover;
    }
  `,
);
