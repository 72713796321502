import i18n from "locales/i18n";

const ar = {
  orders: "الطلبات",
  orderId: "رقم الطلب",
  items: "المنتجات",
  orderItems: "المنتجات",
  selectReason: "سبب الاسترجاع",
  canBeRefunded: "هذا المنتج قابل للاسترجاع",
  canNotBeRefunded: "هذا المنتج غير قابل للاسترجاع",
  refundedItems: "المنتجات المسُتردة",
  yourOrderDoesNotExist: "هذا الطلب غير موجود",
  requiredReason: "من فضلك اختر سبب الاسترجاع",
  next: "التالي",
  quantity: "العدد",
  selectedItem: "منتجات مختارة",
  selectItemToRefund: "اختر المنتج للاسترجاع",
  refundAmount: "مبلغ الاسترجاع",
  productName: "اسم المنتج",
  reason: "سبب الاسترجاع",
  totalRefundAmount: " بتسترد مبلغ",
  warningRefeundInformation:
    "بناءً على سياسة <storePolicy>{{storeName}}</storePolicy>، راح توصلك فلوسك <bolder/>",
  refundProductNotice:
    "سوف تحصل على <strongPrice>{{price}}</strongPrice> مقابل هذا المنتج المسترد",
  refundAfterDelivery: "بعد استلام المتجر للمنتجات المسترجعة",
  refundAfterPickup: "بعد تسليم المنتجات لشركة الشحن",
  loginToYamm: "رفع الطلب",
  signUp: "تسجيل جديد",
  dontHaveAccount: "ليس لديك حساب في يَمّ",
  addressInformation: "عنوان الإستلام",
  chooseShippingAddress: "اختر عنوان الاستلام المناسب",
  addAddress: "أضف عنوان شحن جديد",
  selectAddress: "أضف عنوان شحن جديد",
  selectAddressFromList: "اختر عنوان من العناوين المقترحة",
  browserDeniedGeolocation: "لقد قام متصفحك بحظر تحديد موقعك",
  browserDeniedDescription:
    "يجب عليك تغيير إعدادات المتصفح أو الجهاز للسماح بالوصول إلى موقعك بدقة",
  notSupport: "متصفحك لا يدعم تحديد الموقع الجغرافي",
  notSupportDescription: "نوصي بتحديث متصفحك لتمكين الوصول إلى موقعك",
  skip: "تخطي",
  shippingInformation: "معلومات الشحن",
  loadingMap: "جاري تحميل الخريطة",
  shippingFrom: " موقع التسليم",
  chooseCourier: "إختر شركة الشحن",
  bankTransfer: "تحويل بنكي",
  addBank: "اضافة حساب بنكي جديد",
  iban: "رقم الآيبان",
  invalidIban: "رقم الآيبان المدخل غير صحيح",
  firstName: "الاسم الاول",
  middleName: "الاسم الاوسط",
  lastName: "الاسم الاخير",
  refundDetails: "بيانات الاسترجاع",
  orderDetails: "تفاصيل الطلب",
  orderInfo: "معلومات الطلب",
  storeName: "اسم المتجر",
  totalPrice: "اجمالي قيمة الطلب",
  paidTo: "مدفوع إلى",
  courierInfo: "معلومات الشحن",
  bankInfo: "معلومات الدفع",
  bankName: "اسم البنك",
  regionNotCovered: "هذه المنطقة غير متاحة",
  accountHolderName: " صاحب الحساب",
  submitRequest: "ارفع الطلب",
  agreedToTerms: " اقبل كافة",
  acceptOn: "أقبل",
  refundAndReturnPolicy: "سياسة الاسترجاع والاسترداد بالمتجر",
  refundAndReturnPolicyAcceptance:
    " <storePolicy>سياسة الاستبدال والاسترجاع الخاص ب {{storeName}}</storePolicy>",
  termsAndConditions: "الشروط الاحكام من يَمّ",
  paymentInformation: "معلومات الدفع",
  chooseRefundPaymentMethod: "اختر الحساب المناسب",
  refundTotal: " مبلغ الاسترجاع",
  shippingFees: "تكلفة التوصيل",
  refundOrderCreatedSuccessfully: "رفعنا طلب استرجاع",
  refundOrderUnderReview:
    "رقم الطلب <strongId>{{id}}</strongId> ، بتستلم مدفوعاتك قريب ..",
  refundOrderAfterDelivery:
    "رقم الطلب <strongId>{{id}}</strongId> ، بتستلم مبلغ الاسترجاع بعد استلام المتجر للمنتجات المسترجعة ..",
  refundOrderAfterPickup:
    "رقم الطلب <strongId>{{id}}</strongId> ، سيتم تحويل المبلغ بعد تسليم المنتجات لشركة الشحن ..",
  warningWaitingTimeAfterDelivery:
    "بناءً على سياسة المتجر راح توصلك فلوسك بعد تأكيد المتجر من استلام المنتجات",
  warningWaitingTimeAfterPickup:
    "تحديث حالة الشحن قد تستغرق من ساعة إلى ٤٨ ساعة في بعض الحالات أرجو الاحتفاظ بدليل تسليم المنتج لشركة الشحن",
  refusedToCompleteOrder: "الرجاء الإطلاع على <linkId>{{name}}</linkId>",
  sureDeleting: "هل انت متأكد انك تريد حذف العنوان؟",
  sureDeletingAccount: "هل انت متأكد انك تريد حذف الحساب ؟",
  thisOrderDoesNotExists: "هذا الطلب غير موجود",
  "waiting-shipping": "في انتظار الشحن",
  dropOffConfirmation: "تأكيد تسليم الشحنة",
  dropOffDescription:
    "شركة الشحن المختارة تتطلب تسليم الطلب لأقرب فرع لها ونادرا ما ترسل مناديب لشحنات الاسترجاع، للمتابعة مع نفس الشركة أرجو الموافقة على ذلك أو اختيار شركة شحن أخرى",
  orderSubmitted: "رفعنا طلب استرجاع ، بتستلم مدفوعاتك قريب",
  confirm: "تأكيد",
  itemStatus: "حالة المنتج",
  product: "المنتج",
  price: "السعر",
  total: "إجمالي السعر",
  myOrders: "طلباتي",
  completed: "أُستردت",
  inProgress: "قيد الاسترداد",
  returnReason: "سبب الإسترجاع",
  acceptanceStatus:
    "تنبية: عملية الاسترداد والاسترجاع عبر منصة يَمّ تتبع سياسة الاسترداد والاسترجاع الخاصة بالمتجر ، نأمل مراجعة السياسة قبل الاستمرار",
  storeAgreementLink:
    "انقر هنا لمراجعة سياسة الاسترجاع والاسترداد الخاصة بالمتجر",
  shipmentCost:
    "تنبية: تكلفة سعر الشحنة بحسب سياسة الإسترجاع والاسترداد الخاصة بالمتجر",
  requiredImage: "مطلوب إرفاق ملف بهذا الحقل",
  requiredText: "من فضلك اضف وصف هنا",
  requiredTextPlaceholder: "تفاصيل سبب الإسترجاع",
  attachFile: "أرفق صورة للمنتج المُستلم من كاميرة جوالك",
  attachments: "المرفقات",
  rejectReason: "سبب الإسترجاع",
  fileSizeError: "حجم الملف كبير جداً، يجب أن يكون أقل من 10 ميجا بايت",
  reasonsAttachments: "تفاصيل سبب الاسترجاع",
  illustrativeImage: "صورة توضيحية",
  noCarrierStatement:
    "لا تتوفر شركات شحن بالمدينة التي اخترتها ، الرجاء اختيار اقرب مدينة لك متاحة للتوصيل",
  chooseCity: "اكتب اسم المدينة",
  sureSelectCityPhrase: "لقد اخترت مدينة\t",
  sureSelectCity: "هل انت متآكد من اختيار هذه المدينة ؟",
  attachMultipleFile: "أرفق صورة أو فيديو للمنتج من كاميرة جوالك",
  noFileAttached: "لم تقم بإرفاق اي ملفات",
  mediaIsTooLarge: `لا يجب ان يزيد حجم الملف المرفق عن ${
    process.env.NODE_ENV === "production" ? "٢٥ ميجا" : "٤ ميجا"
  }`,
  sorryCanNotCompleteRefund: "عفوا لا يمكننا رفع طلب الاسترجاع",
  yes: "نعم",
  no: "لا",
  returnPolicyFor: "سياسة الاستبدال والاسترجاع الخاص بـ",
  whyCanNotWeRefund: "السبب:",
  showCustomerAttachments: "عرض مرفقات العميل",
  unacceptableIBANs: "ملاحظة: لا نقبل البنوك الرقمية مثل stc pay",
  phoneNumberConfirmation: "تآكيد رقم الجوال",
  phoneNumberClarification:
    "تم إرسال كلمة مرور للاستخدام مرة واحدة (OTP) لرقم الجوال المربوط بهذا الطلب من فضلك قم كتابته في الحقول ادناه لاكمال عملية الاسترجاع",
};

i18n.addResourceBundle("ar", "app", ar);
