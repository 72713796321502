import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks";
import { useTheme } from "@emotion/react";
import { Flex } from "components/Grids";
import { P2 } from "components/Typography";
import { IconButton } from "components/Buttons";
import { GoBackProps } from "../types";
import Button from "./styles";

export default function GoBack({ callback }: GoBackProps) {
  const {
    ui: { language },
  } = useAppSelector((state) => state);
  const { pallet } = useTheme();
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => {
        if (callback) callback();
        else navigate(-1);
      }}
    >
      <Flex gap={{ xs: 6 }} align="center">
        <IconButton
          icon={`chevron-${language === "ar" ? "right" : "left"}`}
          size="sm"
          variant="secondary"
          as="div"
        />
        <P2
          text="goBack"
          hover={{ decoration: "underline" }}
          color={pallet.primary[500]}
          weight={500}
          capitalizeFirstLetter
        />
      </Flex>
    </Button>
  );
}
