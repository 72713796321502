import React from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import LoadingScreen from "components/LoadingScreen";
import { useAppSelector } from "hooks";
import { NormalLayout, EmptyLayout, NoFooter, SmallFooter } from "app/layout";
import createRouteWithAuthRole from "./createRouteWithAuthRole";
import routes from "./routesConfig";

const layouts = {
  normal: NormalLayout,
  empty: EmptyLayout,
  "no-footer": NoFooter,
  "small-footer": SmallFooter,
};

export default function RouterBuilder() {
  const {
    ui: { layout },
  } = useAppSelector((state) => state);

  const Layout = React.useMemo(() => layouts[layout || "normal"], [layout]);

  return (
    <React.Suspense fallback={<LoadingScreen />}>
      <Layout>
        <Routes>
          {routes.map(createRouteWithAuthRole)}
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </Layout>
    </React.Suspense>
  );
}
