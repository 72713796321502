import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Order, ResponseMeta, CheckOrderForm } from "helpers/types";
import { orderStatusesMock } from "helpers/orderStatusesMock";
import { getAddresses, postAddress } from "./addressesServices";
import getShippingInfo from "./getShippingInfo";
import getPriceInfo from "./getPriceInfo";
import getBankAccounts from "./getBankAccounts";
import checkOrders from "./checkOrders";
import getRefundOrders from "./getRefundOrders";
import fetchRefundReasons from "./fetchRefundReasons";
import {
  OrderType,
  RefundReasonsType,
  ShippingDetailsType,
  PricingDetailsType,
} from "../types";

type OrderState = {
  order: OrderType | null;
  refundReasons: RefundReasonsType[];
  refundOrders: {
    orders: Order[];
    meta?: ResponseMeta;
  };
  addressBook: any[];
  bankAccounts: any[];
  checkOrderFormItems: CheckOrderForm["items"];
  checkOrderStep?: number;
  orderDetails: {
    shippingDetails: ShippingDetailsType | null;
    priceDetails: PricingDetailsType | null;
  };
};

const initialState: OrderState = {
  order: null,
  refundReasons: [],
  addressBook: [],
  bankAccounts: [],
  refundOrders: {
    orders: [],
  },
  checkOrderFormItems: [],
  orderDetails: {
    shippingDetails: null,
    priceDetails: null,
  },
};

const slice = createSlice({
  name: "app/orders",
  initialState,
  reducers: {
    setFormItems: (
      state: OrderState,
      action: PayloadAction<{ items: CheckOrderForm["items"]; step?: number }>,
    ) => {
      return {
        ...state,
        checkOrderFormItems: action.payload.items,
        checkOrderStep: action.payload.step,
      };
    },
    setOrderStep: (state: OrderState, action: PayloadAction<number>) => {
      return {
        ...state,
        checkOrderStep: action.payload,
      };
    },
    flushOrder: (state: OrderState) => {
      return {
        ...state,
        order: null,
      };
    },
  },
  extraReducers: {
    [checkOrders.fulfilled.type]: (state, action) => ({
      ...state,
      order: action.payload,
    }),
    [checkOrders.rejected.type]: (state) => ({ ...state, order: null }),
    [fetchRefundReasons.fulfilled.type]: (state, action) => ({
      ...state,
      refundReasons: action.payload.records,
    }),
    [fetchRefundReasons.rejected.type]: (state) => ({
      ...state,
      refundReasons: [],
    }),
    [getAddresses.fulfilled.type]: (state, action) => ({
      ...state,
      addressBook: action.payload.records,
    }),
    [getAddresses.rejected.type]: (state) => ({
      ...state,
      addressBook: [],
    }),
    [postAddress.fulfilled.type]: (state, action) => ({
      ...state,
      addressBook: action.payload.records,
    }),
    [postAddress.rejected.type]: (state) => ({
      ...state,
      addressBook: [],
    }),
    [getShippingInfo.fulfilled.type]: (state, action) => ({
      ...state,
      orderDetails: {
        ...state.orderDetails,
        shippingDetails: action.payload.records,
      },
    }),
    [getShippingInfo.rejected.type]: (state) => ({
      ...state,
      orderDetails: {
        ...state.orderDetails,
        shippingDetails: null,
      },
    }),
    [getPriceInfo.fulfilled.type]: (state, action) => ({
      ...state,
      orderDetails: {
        ...state.orderDetails,
        priceDetails: action.payload.records,
      },
    }),
    [getPriceInfo.rejected.type]: (state) => ({
      ...state,
      orderDetails: {
        ...state.orderDetails,
        priceDetails: null,
      },
    }),
    [getBankAccounts.fulfilled.type]: (state, action) => ({
      ...state,
      bankAccounts: action.payload.records,
    }),
    [getBankAccounts.rejected.type]: (state) => ({
      ...state,
      bankAccounts: [],
    }),
    [getRefundOrders.fulfilled.type]: (state, { payload }) => {
      const preparedRefundOrders = (payload.records as any[])?.map((order) => {
        const orderStatus = orderStatusesMock.find(
          (mockedOrder) => mockedOrder.id === order.id,
        )?.orderStatuses;
        return {
          ...order,
          orderStatus: orderStatus || [],
        };
      });

      return {
        ...state,
        refundOrders: {
          orders: preparedRefundOrders || [],
          meta: payload.meta,
        },
      };
    },
    [getRefundOrders.rejected.type]: (state) => ({
      ...state,
      refundOrders: {
        orders: [],
        meta: {},
      },
    }),
  },
});

export const { setFormItems, setOrderStep, flushOrder } = slice.actions;
export default slice.reducer;
