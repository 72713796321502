import React from "react";
import getTheme from "theme";
import i18next from "i18next";
import { useAppSelector } from "hooks";

const useLocalTheme = () => {
  const { language } = useAppSelector((state) => state.ui);
  React.useEffect(() => {
    i18next.changeLanguage(language);
  }, [language]);

  return getTheme("light");
};

export default useLocalTheme;
