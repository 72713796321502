import i18n from "locales/i18n";

const ar = {
  welcome: "حياك الله في يَمّ",
  slogan: "أسرع طريقة لاسترجاع أموالك من المتاجر الالكترونية",
  satisfiedCustomers: "عميل مبسوط",
  operationsCount: "متجر",
  successfulRefunds: "عملية استرجاع",
  applyRefund: "بطلب استرداد",
  selectStore: "اختر المتجر",
  orderNumber: "رقم الطلب",
  howTo: "كـــيف استرد مشترياتي؟  ",
  threeSteps: "ثلاث خطوات",
  thatsIt: "هذا كل شيء",
  instantRefund: "إرجاع فوري",
  getRefundOrder: "قم بإنشاء طلب إرجاع",
  yourRefundOrders: "طلباتي",
  applyForOrder: "قدّم طلب استرداد",
  cancel: "إلغاء",
  createRefundOrder: "قدّم طلب استرداد",
  itemInfo: "بيانات المنتج",
  itemName: "اسم المنتج",
  itemPrice: "سعر المنتج",
  quantity: "العدد",
  shippingCompany: "شركة الشحن",
  shippingInfo: "بيانات الشحن",
  shipmentNumber: "رقم الشحنة",
  submittedDate: "تاريخ الطلب  ",
  shippingFees: "قيمة الشحن",
  shippingAddress: "عنوان الشحن",
  deliveryTime: "وقت التوصيل المتوقع",
  refundPrice: "قيمة الاسترجاع",
  totalRefundAmountMinusShippingFees:
    "إجمالي فيمة الاسترجاع مطروح منها قيمة الشحن",
  youHaveNoOrders: "ليس لديك اي طلبات استرجاع",
  noDataFound: "لا يوجد بيانات",
  selectYamm: "اختار يَمّ عند الرغبة في استرجاع المنتجات لمتجرك المفضل ",
  startWithZeroPhoneNumber: "لا يجب ان يبدأ رقم الجوال بصفر",
  refundFormSubmit: "استرجع",
  storeEmailAddress: "البريد الإلكتروني المسجل بالمتجر",
  shipmentDetails: "تفاصيل الشحنة ",
  shipmentDownload: " لتحميل الشحنة",
  shipmentSoon: "سيتم اصدار الشحنة قريبا ...",
  entryErrorHeader: "خطأ في البيانات",
  entryErrorPragraph:
    "هناك خطأ في رقم الطلب او رقم الهاتف، من فضلك تأكد من صحة البيانات المدخلة وحاول مرة اخرى لاحقاً",
  entryErorrButon: "اعد طلب الاسترداد",
};
i18n.addResourceBundle("ar", "app", ar);
