import { RouteType } from "app/routes/types";
import urls from "helpers/urls";

const routes: RouteType[] = [
  {
    route: `${urls.store}/:storeId`,
    element: import("app/modules/store/StoreById"),
  },
  {
    route: `${urls.store}`,
    element: import("app/modules/store/Page"),
  },
];

export default routes;
