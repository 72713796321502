import React from "react";
import { useTheme } from "@emotion/react";
import Icon from "components/Icon";
import { EmptyBtn } from "./styles";

import type { EmptyButtonProps } from "./types";

export default function EmptyFile({ disabled }: EmptyButtonProps) {
  const { pallet, colors } = useTheme();

  return (
    <EmptyBtn disabled={disabled}>
      <Icon
        name="plus"
        color={disabled ? colors.grey[400] : pallet.primary[500]}
      />
    </EmptyBtn>
  );
}
