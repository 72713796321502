import i18n from "locales/i18n";

const en = {
  welcome: "welcome to Yamm",
  slogan: "Fastest way to retrieve your returns money",
  successfulRefund: "successful refund",
  operationsCount: "Operations Count",
  satisfiedCustomers: "Satisfied Customers",
  successfulRefunds: "Successful Refunds",
  applyRefund: "apply for a refund",
  selectStore: "Select Store",
  orderNumber: "Order Number",
  howTo: "how Yamm Works ?",
  threeSteps: "3 Steps",
  thatsIt: "thats it !",
  instantRefund: "Instant REFUNDS!",
  getRefundOrder: "Get Your Refund Now",
  yourRefundOrders: "Your Refund Orders",
  applyForOrder: "Apply for a refund",
  createRefundOrder: "create refund order",
  itemInfo: "item information",
  itemName: "item name",
  itemPrice: "item price",
  shippingCompany: "shipping company",
  shippingInfo: "shipping information",
  shippingFees: "shipping fees",
  shipmentNumber: "shipping number",
  submittedDate: "submitted date",
  shippingAddress: "shipping address",
  deliveryTime: "Delivery Time",
  refundPrice: "refund price",
  totalRefundAmountMinusShippingFees: "total refund amount minus shipping fees",
  youHaveNoOrders: "you have not created any refund order yet",
  noDataFound: "No data is found",
  selectYamm:
    "Select Yamm when returning items to your favourite online retailer",
  startWithZeroPhoneNumber: "phone number should not start with zero",
  refundFormSubmit: "submit",
  storeEmailAddress: "Store registered email address",
  shipmentDetails: "Shipment Details",
  shipmentDownload: "download shipment",
  shipmentSoon: "The shipment will be issued soon",
  entryErrorHeader: "Error in the data",
  entryErrorPragraph:
    "There is an error in the order number or phone number. Please check the entered data and try again later",
  entryErorrButon: "Reapply for a refund",
};

i18n.addResourceBundle("en", "app", en);
