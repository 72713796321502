import OpenSans from "assets/fonts/open-sans";
import IBMPlexSans from "assets/fonts/IBMPlex-sans-arabic";
import pallets, {
  purple,
  blue,
  red,
  orange,
  yellow,
  green,
  grey,
  error,
  shades,
  dark,
} from "./colors";
import shadows from "./shadows";
import gradients from "./gradients";
import { ThemeType } from "./types";

const getTheme = (mode: "dark" | "light"): ThemeType => ({
  font: IBMPlexSans.name,
  pallet: pallets[mode],
  colors: {
    purple,
    blue,
    red,
    orange,
    yellow,
    green,
    grey,
    error,
    shades,
    dark,
  },
  shadows,
  gradients,
  fonts: {
    arabic: IBMPlexSans.name,
    english: OpenSans.name,
  },
});

export default getTheme;
