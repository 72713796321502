import React from "react";
import { ThemeProvider } from "@emotion/react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch, useAuth } from "hooks";
import getTheme from "theme";
import { Helmet, HelmetProvider } from "react-helmet-async";
import FontSetup from "theme/GlobalStyles/FontsSetup";
import GlobalStyles from "theme/GlobalStyles/GlobalStyles";
import "locales";
import Snackbar from "components/Snackbar";

import NafathRevalidation from "app/modules/auth/NafathRevalidation";

type Props = {
  children: React.ReactNode;
};

function AppProvider({ children }: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("app");
  const {
    ui: { language },
    uiActions: { snackbar },
    consumerAuth: { nafathVerificationStatus },
  } = useAppSelector((state) => state);

  const { loggedIn } = useAuth();

  // const [openConfirmModal, setOpenConfirmModal] =
  //   React.useState<boolean>(false);

  // const handleGetFirebaseToken = React.useCallback(async () => {
  //   return getFirebaseToken().then((token) => {
  //     dispatch(setFireBaseToken(token));
  //     setOpenConfirmModal(false);
  //   });
  // }, [dispatch]);

  // const handleOpenFirebaseConfirm = React.useCallback(async () => {
  //   const isMessagingSupported = await isSupported();
  //   if (isMessagingSupported) {
  //     setTimeout(() => {
  //       setOpenConfirmModal(!fireBaseToken);
  //     }, 2500);
  //   }
  // }, [fireBaseToken]);

  // React.useEffect(() => {
  //   handleOpenFirebaseConfirm();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  React.useEffect(() => {
    i18next.changeLanguage(language);
  }, [dispatch, language]);

  // onForegroundMessage()
  //   .then((payload) => {
  //     if (payload) {
  //       const {
  //         notification: { body },
  //       } = payload as any;

  //       dispatch(
  //         showMessage(body as string, {
  //           status: "info",
  //         }),
  //       );
  //     }
  //     // eslint-disable-next-line no-console
  //     console.log("notification payload", payload);
  //   })
  //   .catch(() =>
  //     dispatch(
  //       showMessage("An error occurred while retrieving foreground message. ", {
  //         status: "info",
  //       }),
  //     ),
  //   );
  // React.useEffect(() => {
  //   if (loggedIn) {
  //     dispatch(getUserProfile({}));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loggedIn, nafathVerificationStatus]);

  return (
    <HelmetProvider>
      <ThemeProvider theme={getTheme("light")}>
        <Helmet
          title={t("titleTab")}
          htmlAttributes={{
            lang: language,
            dir: language === "en" ? "ltr" : "rtl",
          }}
          bodyAttributes={{
            lang: language,
            dir: language === "en" ? "ltr" : "rtl",
          }}
        >
          <meta name="description" content={t("metaDescription")} />
          {process.env.NODE_ENV !== "production" && (
            <>
              <meta name="googlebot" content="noindex" />
              <meta name="robots" content="noindex, nofollow" />
              <meta name="bingbot" content="noindex" />
            </>
          )}
        </Helmet>
        <FontSetup />
        <GlobalStyles />
        {children}
        {snackbar?.message && <Snackbar {...snackbar} />}
        {loggedIn && nafathVerificationStatus === "required" && (
          <NafathRevalidation />
        )}
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default AppProvider;
